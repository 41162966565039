import React from 'react'
import PropTypes from 'prop-types'
import { css, createGlobalStyle } from 'styled-components'
import { Grommet } from 'grommet'
import { grommet } from 'grommet/themes'
import CalendarHelmet from './CalendarHelmet'
import CalendarConfigContext from './CalendarConfigContext'
import flatObject from '../utils/flatObject'
import Navbar from '../components/Navbar'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`

const enhancedTheme = (baseTheme, customTheme) => {
  const flatTheme = flatObject(customTheme)
  const colors = {
    ...grommet.global.colors,
    ...flatTheme,
  }

  return {
    ...baseTheme,
    global: {
      ...baseTheme.global,
      colors,
    },
  }
}

const CalendarPageLayout = ({ children }) => (
  <CalendarConfigContext.Provider>
    <GlobalStyle />
    <CalendarConfigContext.Consumer>
      {appConfig => (
        <Grommet
          theme={enhancedTheme(grommet, appConfig.theme)}
          full
          css={css`
            scroll-behavior: smooth;
            overflow-y: scroll;
          `}
        >
          <CalendarHelmet />
	  <Navbar />
          {children}
        </Grommet>
      )}
    </CalendarConfigContext.Consumer>
  </CalendarConfigContext.Provider>
)

CalendarPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CalendarPageLayout
