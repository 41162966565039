import React from 'react'
import { Layer, Box, Text, Button } from 'grommet'
import { FormClose } from 'grommet-icons'
import { Link } from 'gatsby'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import Events from './Calendar/Events'
import moment from 'moment'

const sortyByDate = (eventA, eventB) =>
  moment(eventA.frontmatter.start).isAfter(moment(eventB.frontmatter.start))

const CalendarModalEvent = ({ hideModal, currentDay, events }) => (
  <Layer position="center" onClickOutside={hideModal} onEsc={hideModal} modal>
    <Box
      direction="row"
      align="center"
      tag="header"
      elevation="small"
      justify="between"
    >
      <Text
        margin={{ left: 'small' }}
        color="calendar-modal-text"
        a11yTitle="Selected day"
      >
        <b>{format(new Date(currentDay), 'dddd, MMMM D')}</b>
      </Text>
      <Button
        icon={<FormClose />}
        a11yTitle="Close popup button"
        onClick={hideModal}
      />
    </Box>
    <Box
      direction="column"
      align="center"
      tag="section"
      margin="medium"
      gap="small"
    >
      {events.sort(sortyByDate).map(event => (
        <Box
          elevation="small"
          direction="row"
          fill="horizontal"
          background="calendar-modal-background"
        >
          <Box margin="small">
            <Text
              a11yTitle="Event Title"
              weight="bold"
              size="large"
              color="calendar-modal-text"
            >
              {event.frontmatter.title}
            </Text>

            {event.frontmatter.start && (
              <Text a11yTitle="Event Start" color="calendar-modal-text">
                {moment(event.frontmatter.start).format("hh:mm A")}
              </Text>
            )}
	    
            <p>
              <Link className="button is-small" to={event.fields.slug}>
                More Info →
              </Link>
            </p>

          </Box>
        </Box>
      ))}
    </Box>
  </Layer>
)

CalendarModalEvent.propTypes = {
  hideModal: PropTypes.func.isRequired,
  currentDay: PropTypes.objectOf(Date),
  events: Events.propTypes.events,
}

export default CalendarModalEvent
