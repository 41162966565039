import React, { PureComponent } from 'react'
import { Box, Heading } from 'grommet'
import { StaticQuery, graphql, Link } from 'gatsby'
import Calendar from '../../components/Calendar'
import CalendarModalEvent from '../../components/CalendarModalEvent'
import CalendarListHeader from '../../components/CalendarListHeader'
import CalendarPageLayout from '../../components/CalendarPageLayout'
import groupEventsByMonth from '../../utils/groupEventsByMonth'
import CalendarConfigContext from '../../components/CalendarConfigContext'

class CalendarPage extends PureComponent {
  initialState = {
    currentDay: new Date(),
    eventsOfTheDay: [],
    showModal: false,
  }

  state = this.initialState

  hideModal = () => this.setState(this.initialState)

  showModal = (eventsOfTheDay, currentDay) =>
    this.setState({
      currentDay,
      eventsOfTheDay,
      showModal: true,
    })

  render() {
    const { currentDay, eventsOfTheDay, showModal } = this.state

    return (
      <CalendarPageLayout>

        <Heading margin="small">
	  Events Around the Big Thicket
	</Heading>

        <CalendarListHeader />

	<Box margin="small">
          <Link to="/events">
            View Events as List
          </Link>
        </Box>

        <CalendarConfigContext.Consumer>
          {({ monthsIntoTheFuture }) => (
            <StaticQuery
              query={graphql`
                query CalendarIndexQuery {
                  allMarkdownRemark(
                    sort: { order: ASC, fields: [frontmatter___start] },
                    filter: { frontmatter: { templateKey: { eq: "event-post" } }}
                  ) {
                    edges {
                      node {
                        excerpt(pruneLength: 400)
                        id
                        fields {
                          slug
                        }
                        frontmatter {
                          title
                          templateKey
			  start
			  end
			  entryFee
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
	        <React.Fragment>

                  <Box id="calendars" animation="fadeIn" margin="medium">
                    <Calendar
                      showModal={this.showModal}
                      events={groupEventsByMonth(data, monthsIntoTheFuture)}
                    />
                  </Box>
                </React.Fragment>
              )}
            />
          )}
        </CalendarConfigContext.Consumer>


        {showModal && (
          <CalendarModalEvent
            hideModal={this.hideModal}
            currentDay={currentDay}
            events={eventsOfTheDay}
          />
        )}
      </CalendarPageLayout>
    )
  }
}

export default CalendarPage
